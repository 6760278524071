<template>
  <div>
    <section class="pt-5 mb-4 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="d-flex justify-content-center">
              <img height="70" class="lazyload" :src="shopData.Logo || defaultUrl" >
              <div class="pl-4 text-left">
                  <h1 class="fw-600 h4 mb-0">{{shopData.Name }}
                    <span class="ml-2"><i class="fa fa-check-circle" :style="`color:${shopData.status == 1 ? 'red' : 'green'}`"></i></span>
                  </h1>
                  <div class="rating rating-sm mb-1">
                    <i class="las la-star " v-for="m in 5" :key="m" :class="m < shopData.star ? 'active' : ''"></i>
                  </div>
                  <div class="location opacity-60">{{ shopData.Phone }}</div>
                  <div class="location opacity-60">{{ shopData.Address }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-bottom mt-5"></div>
        <div class="row align-items-center">
          <div class="col-lg-6 order-2 order-lg-0">
            <ul class="list-inline mb-0 text-center text-lg-left">
              <li class="list-inline-item " v-for="(tab, i) in tabList" :key="i"  @click="changeTab(i)">
                <a class="text-reset d-inline-block fw-600 fs-15 p-3 " :class="i == tabIndex ? ' border-bottom border-primary border-width-2' : ''">{{tab}}</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 order-1 order-lg-0">
            <ul class="text-center text-lg-right mt-4 mt-lg-0 social colored list-inline mb-0">
              <li class="list-inline-item" v-if="shopData.Facebook" title="facebook">
                <a  class="facebook"  target="_blank" :href="shopData.Facebook">
                  <i class="lab la-facebook-f"></i>
                </a>
              </li>
              <li class="list-inline-item" v-if="shopData.Instagram" title="instagram">
                <a  class="instagram"  target="_blank" :href="shopData.Instagram">
                  <i class="lab la-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item" v-if="shopData.Twitter" title="twitter">
                  <a  class="twitter"  target="_blank" :href="shopData.Twitter">
                      <i class="lab la-twitter"></i>
                  </a>
              </li>

              <li class="list-inline-item" v-if="shopData.Youtube" title="youtube">
                  <a  class="youtube"  target="_blank" :href="shopData.Youtube">
                      <i class="lab la-youtube"></i>
                  </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!--店铺主页-->

    <section class="mb-5" v-show="tabIndex == 0">
      <div class="container">
        <div class="aiz-carousel dots-inside-bottom mobile-img-auto-height" data-arrows="true" data-dots="true" data-autoplay="true">
          <Swipe ref="banner" :autoplay="3000" :touchable="touchable" indicator-color="#e62e04" class="home-banner">
            <SwipeItem  v-for="(item, i) in bannerList" :key="i">
              <div class="carousel-box" >
                <img class="d-block w-100 lazyload rounded h-200px h-lg-380px img-fit" :src="item">
              </div>
            </SwipeItem>
          </Swipe>

        </div>
      </div>
    </section>
    <section class="mb-4" v-show="tabIndex == 0">
      <div class="container">
        <div class="text-center mb-4">
          <h3 class="h3 fw-600 border-bottom">
            <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">{{ $t('te-se-chan-pin') }}</span>
          </h3>
        </div>
        <div class="row gutters-5 row-cols-xxl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 flex-center">
          <div class="col "  v-for="(item, i) in featureGoodsList" :key="i" >
            <goodsList :goodsInfo="item"></goodsList>
          </div>
        </div>
      </div>
    </section>

    <!--最畅销-->
    <!--所有产品-->
    <section class="mb-4">
      <div class="container">
        <div class="mb-4">
          <h3 class="h3 fw-600 border-bottom">
            <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">
              {{tabIndex == 0 ? $t('shopinfo1') : (tabIndex == 1 ? $t('shopinfo2') : $t('shopinfo3'))}}
            </span>
          </h3>
        </div>
          <div class="row gutters-5 row-cols-xxl-5 row-cols-lg-4 row-cols-md-3 row-cols-2">
            <div class="col mb-3" v-for="(item, i) in allGoodsList" :key="i">
              <goodsList :goodsInfo="item"></goodsList>
            </div>
          </div>
          <div class="aiz-pagination aiz-pagination-center mb-4" v-if="page.total > 1 && tabIndex == 2">
             <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
          </div>
      </div>
    </section>
  </div>
</template>
<script>
import {Swipe, SwipeItem} from 'vant'
import goodsList from '@/components/goodsList'
import pageBar from '@/components/page'
import { getProductList } from '@/api/index'
import { shopPublicInfo } from '@/api/index'
export default {
  components: {
    Swipe,
    SwipeItem,
    goodsList,
    pageBar
  },
  data() {
    return {
      defaultUrl: require('../../assets/imgs/avatar-place.png'),
      tabIndex: 0,
      tabList: [this.$t('shang-dian-zhu-ye'), this.$t('zui-chang-xiao'), this.$t('suo-you-chan-pin')],
      bannerList: [],
      featureGoodsList: [],
      allGoodsList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      },
      dataId: '',
      shopData: {}
    }
  },
  computed: {
    touchable() {
      return this.$store.state.touchable
    },
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
    this.initFeature()
    this.initInfo()
  },
  methods: {
    changePage(page) {
      this.page.page = page
      this.init()
    },
    initInfo() {
      let form = new FormData()
      form.append('ID', this.dataId)
      shopPublicInfo(form).then(res => {
        this.shopData = res.data
        if (this.shopData.Banner) {
          this.bannerList = this.shopData.Banner.split(',')
        }
      })
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Sold', this.tabIndex == 1 ? 1 : '')
      form.append('ShopID', this.dataId)
      getProductList(form).then(res => {
        this.allGoodsList = res.data.Items.map(v => {
          v.Shelves = v.Shelves == 1
          v.Featured = v.Featured == 1
          v.ThroughTrain = v.ThroughTrain == 1
          return v
        })
        this.page.total = res.data.Pagination.totalPage
      })
    },
    initFeature() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Sold', this.tabIndex == 1 ? 1 : '')
      form.append('ShopID', this.dataId)
      form.append('Featured', 1)
      getProductList(form).then(res => {
        this.featureGoodsList = res.data.Items.map(v => {
          v.Shelves = v.Shelves == 1
          v.Featured = v.Featured == 1
          v.ThroughTrain = v.ThroughTrain == 1
          return v
        })
        this.page.total = res.data.Pagination.totalPage
      })
    },
    changeTab(i) {
      this.tabIndex = i
      if (this.page.page != 1) {
        this.page.page = 1
      }
      this.init()
    }
  }
}
</script>